import React from "react";
import styled, { CSSObject } from "styled-components";
import { replaceToken } from "../../private/helpers";
import forwardRef from "../../private/forwardRef";
import { InlineProps } from "./inline.types";

const defaultProps = {
    inset: "0",
    gap: "1.5rem",
    grow: true,
    justifyContent: "flex-start",
    alignItems: "stretch"
}

type InlineContainerProps = {
    justifyContent: CSSObject["justifyContent"]
    alignItems: CSSObject["alignItems"]
    inset: string
    grow: boolean
    gap: string
}

const InlineContainer = styled.div<InlineContainerProps>`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems};
    padding: ${({ theme, inset }) => replaceToken(theme.spacing, inset)};
    & > * {
        flex-grow: ${({ grow }) => (grow ? 1 : 0)};
    }
    & > *:not(:last-child) {
        margin-right: ${({ theme, gap }) => replaceToken(theme.spacing, gap)};
    }
`

const Inline = forwardRef<InlineProps, "div">((props, externalRef) => {
    const { inset, gap, justifyContent, alignItems, grow, children, ...otherProps } = {
        ...defaultProps,
        ...props
    }

    return (
        <InlineContainer
            ref={externalRef}
            inset={inset}
            gap={gap}
            justifyContent={justifyContent}
            alignItems={alignItems}
            grow={grow}
            {...otherProps}
        >
            {children}
        </InlineContainer>
    )
})

Inline.displayName = "Inline"

export default Inline;