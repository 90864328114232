import React from "react";
import { ListboxOption as ListboxOptionStyle } from "./styles";
import forwardRef from "../../private/forwardRef";
import { ListboxOptionProps } from "./listbox-option.types";

const ListboxOption = forwardRef<ListboxOptionProps, "li">(
    (props, externalRef) => <ListboxOptionStyle ref={externalRef} {...props} />
)

ListboxOption.displayName = "ListboxOption";

export default ListboxOption;