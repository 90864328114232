import {
    NittyDefaults,
    BorderTheme,
    SpacingTheme,
    TypographyTheme,
} from './types';

const colors = {
    brand: '#f16c52',
    white: '#ffffff',

    snowgum50: "#F6F5F7",
    snowgum100: "#E5E3E8",
    snowgum200: "#BEBAC2",
    snowgum300: "#959199",
    snowgum400: "#726E75",
    snowgum500: "#565359",
    snowgum600: "#3D3B40",
    snowgum700: "#2E2C30",
    snowgum800: "#242326",
    snowgum900: "#1B1A1C",
    snowgum1000: "#0F0E0F",

    bondi50: "#DBF7FF",
    bondi200: "#7CD3F3",
    bondi400: "#0078BD",
    bondi500: "#00639E",
    bondi700: "#10384F",

    eucalyptus50: "#DCFCE7",
    eucalyptus200: "#93D7AD",
    eucalyptus400: "#2D8659",
    eucalyptus500: "#137044",
    eucalyptus700: "#02452A",

    outback50: "#FEEEDB",
    outback200: "#FFA857",
    outback300: "#ED711F",
    outback400: "#D15408",
    outback500: "#A8420D",
    outback700: "#542615",

    sorrento50: "#FFF4D4",
    sorrento200: "#FCCA36",
    sorrento300: "#DCA218",
    sorrento500: "#995C00",
    sorrento700: "#5C2E00",

    whitsundays50: "#DDFCFA",
    whitsundays200: "#5AE2E0",
    whitsundays300: "#2BC5CA",
    whitsundays400: "#189AA3",
    whitsundays500: "#0C747D",
    whitsundays700: "#143C42",

    jacaranda50: "#F9EBFF",
    jacaranda200: "#D5BIEC",
    jacaranda400: "#8154AB",
    jacaranda500: "#66408D",
    jacaranda700: "#3A2459",

    coral50: "#FFE8EB",
    coral200: "#FFA3AB",
    coral300: "#52636F",
    coral400: "#CE3548",
    coral500: "#A81E35",
    coral700: "#571D2A",
};

export const palette = {
    /* Text Tokens */
    textPrimary: {
        light: colors.snowgum600,
        dark: colors.snowgum50,
    },
    textSecondary: {
        light: colors.snowgum400,
        dark: colors.snowgum200,
    },
    textInverted: {
        light: colors.white,
        dark: colors.snowgum600,
    },
    textInvertedStatic: {
        light: colors.white,
        dark: colors.snowgum50,
    },
    textInformation: {
        light: colors.bondi500,
        dark: colors.bondi200,
    },
    textSuccess: {
        light: colors.eucalyptus500,
        dark: colors.eucalyptus200,
    },
    textWarning: {
        light: colors.outback500,
        dark: colors.outback200,
    },
    textSuccessInverted: {
        light: colors.eucalyptus200,
        dark: colors.eucalyptus500,
    },
    textWarningInverted: {
        light: colors.outback200,
        dark: colors.outback500,
    },
    textError: {
        light: colors.coral500,
        dark: colors.coral200,
    },
    textBrand: {
        light: colors.brand,
        dark: colors.snowgum50,
    },

    /* Background Tokens */
    backgroundBrandStatic: {
        light: colors.brand,
        dark: colors.brand
    },
    backgroundPrimary: {
        light: colors.white,
        dark: colors.snowgum700
    },
    backgroundPrimaryBase: {
        light: colors.white,
        dark: colors.snowgum1000
    },
    backgroundPrimaryLow: {
        light: colors.white,
        dark: colors.snowgum900
    },
    backgroundPrimaryHigh: {
        light: colors.white,
        dark: colors.snowgum800
    },
    backgroundPrimaryInverted: {
        light: colors.snowgum600,
        dark: colors.snowgum100
    },
    backgroundPrimaryInvertedStatic: {
        light: colors.snowgum600,
        dark: colors.snowgum600
    },
    backgroundSecondaryBase: {
        light: colors.snowgum50,
        dark: colors.snowgum1000
    },
    backgroundSecondaryInvertedStatic: {
        light: colors.snowgum800,
        dark: colors.snowgum800
    },
    backgroundSecondary: {
        light: colors.snowgum100,
        dark: colors.snowgum600
    },
    backgroundInformation: {
        light: colors.bondi50,
        dark: colors.bondi700
    },
    backgroundSuccess: {
        light: colors.eucalyptus50,
        dark: colors.eucalyptus700
    },
    backgroundWarning: {
        light: colors.outback50,
        dark: colors.outback700
    },
    backgroundError: {
        light: colors.coral50,
        dark: colors.coral700
    },
    backgroundOverlayStatic: {
        light: "rgba(36, 34, 38, 0.8)",
        dark: "rgba(36, 34, 38, 0.8)"
    },

    /* Border Tokens */
    borderBrandStatic: {
        light: colors.brand,
        dark: colors.brand
    },
    borderPrimary: {
        light: colors.snowgum300,
        dark: colors.snowgum300
    },
    borderSecondary: {
        light: colors.snowgum100,
        dark: colors.snowgum500
    },
    borderInverted: {
        light: colors.white,
        dark: colors.snowgum600
    },
    borderInvertedStatic: {
        light: colors.snowgum300,
        dark: colors.snowgum300
    },
    borderErrored: {
        light: colors.outback500,
        dark: colors.outback300
    },
    borderSelected: {
        light: colors.snowgum600,
        dark: colors.snowgum50
    },

    /* Link Tokens */
    linkPrimary: {
        light: colors.bondi500,
        dark: colors.bondi200
    },
    linkSecondary: {
        light: colors.snowgum400,
        dark: colors.snowgum200,
    },

    /* Interaction Tokens */
    backgroundHoveredBrandStatic: {
        light: colors.coral500,
        dark: colors.coral300
    },
    backgroundHoveredPrimary: {
        light: colors.snowgum50,
        dark: colors.snowgum600,
    },
    backgroundHoveredPrimaryInverted: {
        light: colors.snowgum800,
        dark: colors.snowgum50,
    },
    backgroundHoveredPrimaryInvertedStatic: {
        light: colors.snowgum800,
        dark: colors.snowgum500
    },
    backgroundHoveredSecondary: {
        light: colors.snowgum200,
        dark: colors.snowgum500,
    },
    backgroundPressedBrandStatic: {
        light: colors.coral500,
        dark: colors.coral300,
    },
    backgroundPressedPrimary: {
        light: colors.snowgum50,
        dark: colors.snowgum600,
    },
    backgroundPressedPrimaryInverted: {
        light: colors.snowgum800,
        dark: colors.snowgum50,
    },
    backgroundPressedPrimaryInvertedStatic: {
        light: colors.snowgum800,
        dark: colors.snowgum500,
    },
    backgroundPressedSecondary: {
        light: colors.snowgum200,
        dark: colors.snowgum500,
    },
    backgroundFocusedPrimary: {
        light: colors.snowgum50,
        dark: colors.snowgum500,
    },
    backgroundSelected: {
        light: colors.snowgum600,
        dark: colors.snowgum50,
    },
    borderHovered: {
        light: colors.snowgum600,
        dark: colors.snowgum200,
    },
    borderHoveredInverted: {
        light: colors.snowgum50,
        dark: colors.snowgum500,
    },
    borderPressed: {
        light: colors.snowgum50,
        dark: colors.snowgum200,
    },
    borderPressedInverted: {
        light: colors.snowgum600,
        dark: colors.snowgum500,
    },
    borderFocused: {
        light: colors.snowgum600,
        dark: colors.snowgum50,
    },
    borderFocusedInvertedStatic: {
        light: colors.snowgum50,
        dark: colors.snowgum50,
    },

    /* Disabled Tokens */
    textDisabled: {
        light: colors.snowgum200,
        dark: colors.snowgum500
    },
    backgroundDisabled: {
        light: colors.snowgum50,
        dark: colors.snowgum600,
    },
    borderDisabled: {
        light: colors.snowgum100,
        dark: colors.snowgum500,
    },

    /* Accent Tokens */
    graphicOrange: {
        light: colors.outback300,
        dark: colors.outback400,
    },
    graphicYellow: {
        light: colors.sorrento200,
        dark: colors.sorrento300,
    },
    graphicTeal: {
        light: colors.whitsundays300,
        dark: colors.whitsundays400,
    },
    graphicBlue: {
        light: colors.bondi400,
        dark: colors.bondi400,
    },
    graphicPurple: {
        light: colors.jacaranda400,
        dark: colors.jacaranda400,
    },
    graphicPink: {
        light: colors.coral300,
        dark: colors.coral400,
    },
    graphicGreen: {
        light: colors.eucalyptus400,
        dark: colors.eucalyptus400,
    },

    /* Accent Background Tokens */
    backgroundBlue: {
        light: colors.bondi50,
        dark: colors.bondi700,
    },
    backgroundPink: {
        light: colors.coral50,
        dark: colors.coral700,
    },
    backgroundGreen: {
        light: colors.eucalyptus50,
        dark: colors.eucalyptus700,
    },
    backgroundPurple: {
        light: colors.jacaranda50,
        dark: colors.jacaranda700,
    },
    backgroundOrange: {
        light: colors.outback50,
        dark: colors.outback700,
    },
    backgroundYellow: {
        light: colors.sorrento50,
        dark: colors.sorrento700,
    },
    backgroundTeal: {
        light: colors.whitsundays50,
        dark: colors.whitsundays700,
    },

    /* Accent Text Tokens */
    textBlue: {
        light: colors.bondi500,
        dark: colors.bondi200,
    },
    textPink: {
        light: colors.coral500,
        dark: colors.coral200,
    },
    textGreen: {
        light: colors.eucalyptus500,
        dark: colors.eucalyptus200,
    },
    textPurple: {
        light: colors.jacaranda500,
        dark: colors.jacaranda200,
    },
    textOrange: {
        light: colors.outback500,
        dark: colors.outback200,
    },
    textYellow: {
        light: colors.sorrento500,
        dark: colors.sorrento200,
    },
    textTeal: {
        light: colors.whitsundays500,
        dark: colors.whitsundays200,
    },
};

const spacing: SpacingTheme = {
    twoExtraSmall: "0.25rem",
    extraSmall: '0.5rem',
    small: '0.75rem',
    medium: '1rem',
    large: '1.5rem',
    extraLarge: '2rem',
    twoExtraLarge: "2.5rem",
    threeExtraLarge: "3rem"
};

const fontFaces = {
    MontserratText: {
        fontFamily:
            '"Montserrat", Helvetica, Arial, sans-serif',
        fontVariationSettings: '"XTDR" 0, "APRT" 0, "SPAC" 0',
    },
    MontserratHeading: {
        fontFamily:
            '"Montserrat", Helvetica, Arial, sans-serif',
        fontVariationSettings: '"XTDR" 0, "APRT" 0, "SPAC" 0',
    },
};

const fonts = {
    lightText: {
        ...fontFaces.MontserratText,
        fontWeight: 300,
    },
    regularText: {
        ...fontFaces.MontserratText,
        fontWeight: 400,
    },
    mediumText: {
        ...fontFaces.MontserratText,
        fontWeight: 500,
    },
    semiBoldText: {
        ...fontFaces.MontserratText,
        fontWeight: 600,
    },
    boldText: {
        ...fontFaces.MontserratText,
        fontWeight: 700,
    },
    light: {
        ...fontFaces.MontserratHeading,
        fontWeight: 300,
    },
    regular: {
        ...fontFaces.MontserratHeading,
        fontWeight: 400,
    },
    medium: {
        ...fontFaces.MontserratHeading,
        fontWeight: 500,
    },
    semiBold: {
        ...fontFaces.MontserratHeading,
        fontWeight: 600,
    },
    bold: {
        ...fontFaces.MontserratHeading,
        fontWeight: 700,
    },
};

const typography: TypographyTheme = {
    display: {
        fontSize: '3rem',
        lineHeight: '4rem',
        ...fonts.regular,
    },
    largeTitle: {
        fontSize: '2.125rem',
        lineHeight: '2.75rem',
        ...fonts.semiBold,
    },
    title01: {
        fontSize: '1.5rem',
        lineHeight: '2.25rem',
        ...fonts.semiBold,
    },
    title02: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
        ...fonts.semiBold,
    },
    title03: {
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        ...fonts.semiBold,
    },
    subtitle01: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        ...fonts.medium,
    },
    subtitle02: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        ...fonts.medium,
    },
    body01: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        ...fonts.regularText,
    },
    body02: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        ...fonts.regularText,
    },
    caption: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        ...fonts.regularText,
    },
    overline: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        textTransform: "uppercase",
        letterSpacing: "0.025rem",
        ...fonts.regularText,
    },
    strong: {
        fontWeight: 600,
    },
};

const border: BorderTheme = {
    width: {
        thin: '0.0625rem',
        thick: '0.125rem',
    },
    radius: {
        small: '0.25rem',
        medium: '0.5rem',
        large: '0.75rem',
        extraLarge: '1.5rem',
    },
};

const shadow = '0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.16)';

const defaults: NittyDefaults = {
    palette,
    spacing,
    typography,
    border,
    shadow,
};

export default defaults;
