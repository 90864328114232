import React from 'react';
import styled from 'styled-components';
import ButtonBase from './button-base';
import forwardRef from '../../private/forwardRef';
import {
    ButtonProps,
    ButtonIconPlacement,
    ButtonSize,
    ButtonVariant,
    ButtonAsLinkVariant
} from './button.types'

import LinkBase from '../link/link-base';

const ButtonWithLinkVariant = styled(LinkBase).attrs({ as: "button" })`
    display: inline;
    font-size: inherit;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
`

type LabelWithMarginProps = {
    iconPlacement: ButtonIconPlacement,
    sizeVariant: ButtonSize
}

const LabelWithMargin = styled.span<LabelWithMarginProps>`
    ${({ iconPlacement, theme, sizeVariant }) => 
        `margin-${iconPlacement}: ${
            sizeVariant === "small"
                ? theme.spacing.twoExtraSmall
                : theme.spacing.extraSmall
        }`};
`

const IconWrapper = styled.span`
    display: inline-flex;
    vertical-align: text-top;
`

const defaultProps = {
    variant: "primary" as ButtonVariant | ButtonAsLinkVariant,
    sizeVariant: "large" as ButtonSize,
    fullWidth: false,
    compact: false,
    disabled: false,
    hideLabel: false,
    icon: null,
    iconPlacement: "left" as ButtonIconPlacement,
}

const Button = forwardRef<ButtonProps, "button">((props, ref) => {
    const {
        children,
        variant,
        icon,
        iconPlacement,
        hideLabel,
        sizeVariant,
        fullWidth,
        compact,
        dangerouslySetClassNames,
        ...rest
    } = { ...defaultProps, ...props }

    const linkVariantMatches = variant.match(/link-(.*)/)
    const variantName = linkVariantMatches ? linkVariantMatches[1] : variant;

    const renderLabel = () => {
        if(icon) {
            return (
                <LabelWithMargin
                    iconPlacement={iconPlacement}
                    sizeVariant={sizeVariant}
                    className={dangerouslySetClassNames?.label}
                >
                    {children}
                </LabelWithMargin>
            )
        }

        return children;
    }

    const renderIcon = () => {
        if(icon) {
            return linkVariantMatches ? (
                <IconWrapper className={dangerouslySetClassNames?.iconWrapper}>
                    {icon}
                </IconWrapper>
            ) : (
                icon
            )
        }
    }

    const commonProps = {
        $variant: variantName,
        $sizeVariant: sizeVariant,
        $fullWidth: fullWidth,
        $compact: compact,
        "aria-label": hideLabel ? children : null,
        ref,
        ...rest,
    }

    const renderIconAndLabel = () => (
        <>
            {iconPlacement === "left" && renderIcon()}
            {!hideLabel ? renderLabel() : null}
            {iconPlacement === "right" && renderIcon()}
        </>
    )

    return linkVariantMatches ? (
        <ButtonWithLinkVariant {...commonProps}>
            {renderIconAndLabel()}
        </ButtonWithLinkVariant>
    ) : (
        <ButtonBase {...commonProps}>{renderIconAndLabel()}</ButtonBase>
    )
})

Button.displayName = "Button"

export default Button;
export { ButtonBase }

