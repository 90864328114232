import React, { ReactElement } from "react";
import styled from "styled-components";
import { SpriteSheetProps } from "./sprite.types"
import forwardRef from '../../private/forwardRef';

const HiddenSvg = styled.svg`
    display: none;
`

const SpriteSheet = forwardRef<SpriteSheetProps, "svg">(
    ({ sprites, spriteSheetId, ...props}, ref) => {
        return (
            <HiddenSvg ref={ref} {...props}>
                {Object.keys(sprites)?.map(id => {
                    const sprite = sprites[id];
                    const prefixedId = spriteSheetId ? `${spriteSheetId}-${id}` : id;
                    return React.isValidElement(sprite)
                        ? React.cloneElement(sprite as ReactElement, {
                            id: prefixedId,
                            as: "symbol",
                            key: prefixedId
                        })
                        : null
                })}
            </HiddenSvg>
        )

})

SpriteSheet.displayName = "SpriteSheet";

export default SpriteSheet;