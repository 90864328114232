import React, { useCallback, useEffect, useState } from "react";
import ReactModal from 'react-modal';
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { useInnerHeight } from '../../private/useInnerHeight';
import { ModalProps, ReactModalProps } from './modal.types';
import { AnimatedModal } from './styles';

const enableScrollList = 
    '[data-modal-scroll-enable], [role="listbox"], [role="textbox"], [role="combobox"]';

const defaultProps = {
    reactModalProps: {} as ReactModalProps,
    bypassScrollLock: false
}

const Modal = (props: ModalProps) => {
    const { 
        getAppElement,
        onRequestClose,
        children,
        opened,
        reactModalProps,
        bypassScrollLock,
        className
    } = { ...defaultProps, ...props }

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => ReactModal.setAppElement(getAppElement()), [getAppElement]);
    useEffect(() => setIsOpen(opened), [opened]);

    const innerHeight = useInnerHeight();

    const scrollRef = useCallback((node: HTMLDivElement) => {
        node &&
            node.querySelectorAll(enableScrollList)
            .forEach((target: HTMLElement | Element) => disableBodyScroll(target));

        return node ? disableBodyScroll(node) : clearAllBodyScrollLocks();
    }, [])
    const scrollContentRef = bypassScrollLock ? undefined : scrollRef;

    const {
        style: styleOverrides = {},
        contentRef,
        portalClassName,
        className: reactModalClassName,
        closeTimeoutMS = 200,
        ...reactModalPropsRest
    } = reactModalProps;

    const {
        overlay: overlayStyleOverrides = {},
        content: contentStyleOverrides = {}
    } = styleOverrides;

    const ModalComponent = reactModalClassName ? ReactModal : AnimatedModal;

    return (
        <ModalComponent
            contentRef={instance => {
                contentRef && contentRef(instance)
                scrollContentRef && scrollContentRef(instance)
            }}
            onRequestClose={onRequestClose}
            portalClassName={
                [portalClassName, className].filter(Boolean).join(" ") || undefined
            }
            className={reactModalClassName}
            isOpen={isOpen}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 500,
                    backgroundColor: undefined,
                    ...overlayStyleOverrides
                },
                content: {
                    border: "none",
                    backgroundColor: "unset",
                    position: "unset",
                    borderRadius: "unset",
                    padding: 0,
                    overflow: "auto",
                    maxHeight: `${innerHeight}`,
                    ...contentStyleOverrides
                }
            }}
            closeTimeoutMS={closeTimeoutMS}
            {...reactModalPropsRest}
            >
                {children}
            </ModalComponent>
    )
}

Modal.displayName = "Modal";

export default Modal;