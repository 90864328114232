const mapObject = <KeyType extends string, ValueType, ReturnValueType = string>(
    obj: Record<KeyType, ValueType>,
    mapFn: (key: KeyType, value?: ValueType) => ReturnValueType,
) => {
    const keys = Object.keys(obj) as KeyType[];
    return keys.reduce(
        (newObject: Record<KeyType, ReturnValueType>, key: KeyType) => ({
            ...newObject,
            [key]: mapFn(key, obj[key]),
        }),
        {} as Record<KeyType, ReturnValueType>,
    );
};

export { mapObject };
