import React from "react"
import styled from "styled-components"
import forwardRef from "../../private/forwardRef"
import { NittyTheme } from 'js/@nitty/components/nitty/types'
import { TextVariant, TextProps } from "./text.types"

type TextProperties = {
    theme: NittyTheme
    variant: TextVariant
}

const textProperties = ({ theme, variant }: TextProperties) => {
    const {
        fontSize,
        fontFamily,
        lineHeight,
        fontWeight,
        textTransform,
        letterSpacing,
        fontVariationSettings
    } = theme.typography[variant]

    return {
        fontSize,
        fontFamily,
        lineHeight,
        fontWeight,
        textTransform,
        letterSpacing,
        fontVariationSettings,
        margin: 0
    }
}

const Typography = styled.p(textProperties)

const Text = forwardRef<TextProps, "p">(
    ({ as, variant, children, ...props }, ref) => {

        const defaultStylesForElement: Record<string, string> = {
            h1: "largeTitle",
            h2: "title01",
            h3: "title02",
            h4: "title03",
            h5: "overline",
            h6: "overline",
            p: "body01",
            small: "body02",
            figcaption: "caption",
            label: "body01",
            strong: "strong"
        };

        const defaultStyleIfAs = typeof as === "string" && defaultStylesForElement[as];

        return (
            <Typography
                ref={ref}
                as={as}
                variant={variant || defaultStyleIfAs || "body01"}
                {...props}
            >
                {children}
            </Typography>
        )
    }
)

Text.displayName = "Text"

export default Text;
export {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    P,
    Small,
    FigCaption,
    Strong
} from "./shorthand-text"
