import React from "react";
import forwardRef from "../../private/forwardRef";
import { spacingHelper } from "../../utils";
import styled from "styled-components";
import { DividerProps } from './divider.types';

const HR = styled.hr`
    border: none;
    border-top: ${({ theme }) => `${theme.border.width.thin} solid ${theme.palette.borderSecondary}`};
    margin: ${spacingHelper("threeExtraLarge 0")};
`

const Divider = forwardRef<DividerProps, "hr">((props, ref) => (
    <HR ref={ref} {...props} />
));

Divider.displayName = "Divider"

export default Divider;