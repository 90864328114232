import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import forwardRef from "../../private/forwardRef";
import {
    MultiAutocompleteOption,
    MultiAutocompleteProps,
    MultiAutocompleteFilterOptions,
    MultiAutocompleteMapOptionsToListboxNode,
    MultiAutocompleteMapOptionToListNode,
    MultiAutocompleteMapOptionToString
} from './multi-autocomplete.types';
import {
    ContentWrapper,
    IconWrapper,
    ComponentContainer,
    StyledLabel,
    ListboxWrapper,
    Listbox,
    NoResultsListItem
} from "./styles";
import Pill from "./pill";
import { useAutoId } from "../../private/helpers";
import { useCombobox, useMultipleSelection } from "downshift";
import usePopper from "../../hooks/usePopper";
import TextInputBase from "../text-input/text-input-base";
import ListboxOption from "./listbox-option";
import { P, Strong } from "../text";
import { TextInputBaseSquaredCorners } from "../text-input/text-input-base.types";

const isPlacementTopOrBottom = (
    placement: any
): placement is TextInputBaseSquaredCorners =>
    placement === "top" || placement === "bottom"

const defaultMapOptionToListNode: MultiAutocompleteMapOptionToListNode = ({
    option,
    inputValue,
    isActive,
    mapOptionToString
}) => {
    const textToHighLight = inputValue || "";
    const optionText = mapOptionToString(option)
    const inputStart = optionText
        .toLowerCase()
        .indexOf(textToHighLight.toLowerCase())
    const inputEnd = inputStart + textToHighLight.length;
    const containsInputValue = inputValue && inputStart !== -1;

    return (
        <ListboxOption sizeVariant="large" isActive={isActive}>
            {containsInputValue ? (
                <P>
                    <span>
                        {optionText.substring(0, inputStart)}
                        <Strong>{optionText.substring(inputStart, inputEnd)}</Strong>
                        {optionText.substring(inputEnd)}
                    </span>
                </P>
            ) : (
                <span>{optionText}</span>
            )}
        </ListboxOption>
    )
}

const defaultMapOptionsToListboxNode: MultiAutocompleteMapOptionsToListboxNode =
    ({
        options,
        inputValue,
        mapOptionToString,
        mapOptionToListNode,
        getItemProps,
        highlightedIndex,
        mapOptionToAccessibleString,
        dangerouslySetClassNames,
        noResultsMessage,
        noResultsState
    }) => {
        return noResultsState ? (
            <NoResultsListItem
                sizeVariant="large"
                className={dangerouslySetClassNames?.noResultsMessage}
            >
                {noResultsMessage}
            </NoResultsListItem>
        ) : (
            <>
                {options.map((option, index) =>
                    React.cloneElement(
                        mapOptionToListNode({
                            option,
                            inputValue,
                            mapOptionToString,
                            isActive: highlightedIndex === index
                        }),
                        getItemProps({
                            item: option,
                            index,
                            key: index,
                            className: dangerouslySetClassNames?.listboxOption,
                            "aria-label": mapOptionToAccessibleString(option)
                        })
                    )
                )}
            </>
        )
    }

const defaultMapOptionToString: MultiAutocompleteMapOptionToString = option =>
    option.toString()

const defaultAreOptionsEqual = (
    option1: MultiAutocompleteOption,
    option2: MultiAutocompleteOption,
    mapOptionToString: MultiAutocompleteMapOptionToString = defaultMapOptionToString
) => {
    return mapOptionToString(option1) === mapOptionToString(option2)
}

const defaultFilterOptions: MultiAutocompleteFilterOptions = (
    options,
    inputValue,
    mapOptionToString
) =>
    options.filter(option =>
        mapOptionToString(option).toLowerCase().includes(inputValue.toLowerCase())
    )

const defaultProps = {
    options: [],
    hideLabel: false,
    helpMessage: "",
    errorMessage: "",
    placeholder: "",
    disabled: false,
    listboxMaxHeight: "15.8125rem",
    onSelectedOptionsChange: () => { },
    onInputChange: () => { },
    mapOptionToString: defaultMapOptionToString,
    mapOptionToListNode: defaultMapOptionToListNode,
    areOptionsEqual: defaultAreOptionsEqual,
    inputMinWidth: "auto",
    onNoResultsChange: () => { },
    noResultsMessage: "No results",
    filterOptions: defaultFilterOptions,
    disableListboxFlip: false,
    mapOptionsToListboxNode: defaultMapOptionsToListboxNode
}

const getNoResultsState = (noResults: boolean, userNoResults?: boolean) => userNoResults !== undefined ? userNoResults : noResults

const MultiAutocomplete = forwardRef<MultiAutocompleteProps, "input">(
    (props, ref) => {
        const {
            options,
            inputValue,
            selectedOptions,
            filterOptions,
            onInputChange,
            onSelectedOptionsChange,
            mapOptionToListNode,
            mapOptionToString,
            listboxMaxHeight,
            visibleOptionsToDisplay,
            required,
            areOptionsEqual: userAreOptionsEqual,
            label,
            id,
            name,
            disabled,
            hideLabel,
            icon,
            className,
            style,
            dangerouslySetClassNames,
            openOnFocus,
            onNoResultsChange,
            noResults: userNoResults,
            noResultsMessage,
            showClearButton,
            disableAutomaticHighlight,
            disableListboxFlip,
            mapOptionsToListboxNode,
            errorMessage,
            ...otherProps
        } = { ...defaultProps, ...props };

        const autoId = useAutoId(id);
        const [allowBackspacePill, setAllowBackSpacePill] = useState(false);
        const [availableOptions, setAvailableOptions] = useState(options);
        const [filteredOptions, setFilteredOptions] = useState(availableOptions);

        const [noResults, setNoResults] = useState(false);

        const areOptionsEqual = userAreOptionsEqual || defaultAreOptionsEqual;

        const {
            isOpen,
            getLabelProps,
            getMenuProps,
            getInputProps,
            getComboboxProps,
            highlightedIndex,
            getItemProps,
            selectItem,
            inputValue: internalInputValue,
            setInputValue,
            openMenu
        } = useCombobox({
            ...((inputValue || inputValue === "") && { inputValue }),
            id: autoId,
            items: filteredOptions,
            defaultHighlightedIndex: disableAutomaticHighlight ? undefined : 0,
            getA11ySelectionMessage: () => `The option has been selected. There are now ${selectedItems.length} selections.`,
            onStateChange: ({ inputValue, type, selectedItem }) => {
                switch (type) {
                    case useCombobox.stateChangeTypes.InputChange:
                        inputValue !== undefined && onInputChange(inputValue)
                        break
                    case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    case useCombobox.stateChangeTypes.ItemClick:
                    case useCombobox.stateChangeTypes.InputBlur:
                        if (selectedItem) {
                            onInputChange("")
                            addSelectedItem(selectedItem)
                            selectItem(null)
                        }
                        break
                    default:
                        break;
                }
            },
            stateReducer: (state, { type, changes }) => {
                const { InputKeyDownEnter, ItemClick } = useCombobox.stateChangeTypes;
                if (type === ItemClick || type === InputKeyDownEnter) {
                    if (getNoResultsState(noResults, userNoResults)) return state
                    else return { ...changes, inputValue: state.inputValue }
                }
                return changes
            }
        })

        const {
            getSelectedItemProps,
            getDropdownProps,
            addSelectedItem,
            removeSelectedItem,
            selectedItems,
            reset
        } = useMultipleSelection({
            ...(selectedOptions && { selectedItems: selectedOptions }),
            getA11yRemovalMessage: ({ removedSelectedItem }) =>
                `${mapOptionToAccessibleString(
                    removedSelectedItem
                )} has been deselected.`,
            onSelectedItemsChange: ({ selectedItems }) => {
                if (selectedItems) {
                    onSelectedOptionsChange(selectedItems)
                }
            }
        })

        useEffect(() => {
            setNoResults(internalInputValue !== "" && filteredOptions.length === 0)
        }, [internalInputValue, filteredOptions.length])

        useEffect(() => {
            onNoResultsChange(noResults)
        }, [noResults, onNoResultsChange])

        useEffect(() => {
            setAvailableOptions(
                options.filter(
                    option =>
                        !selectedItems.some(selectedItem =>
                            areOptionsEqual(selectedItem, option, mapOptionToString)
                        )
                )
            )
        }, [areOptionsEqual, options, selectedItems, mapOptionToString])

        useEffect(() => {
            setAllowBackSpacePill(false);
            setFilteredOptions(
                filterOptions(availableOptions, internalInputValue, mapOptionToString)
            )
        }, [filterOptions, availableOptions, internalInputValue, mapOptionToString])

        const {
            mapOptionToAccessibleString = mapOptionToString,
            ...remainingProps
        } = otherProps;

        const shouldDisplayListbox =
            isOpen &&
            (filteredOptions.length > 0 ||
                getNoResultsState(noResults, userNoResults))

        const listboxRef = useRef<HTMLUListElement>(null);

        useEffect(() => {
            if (shouldDisplayListbox && listboxRef.current) {
                listboxRef.current.scrollTop = 0;
            }
        }, [shouldDisplayListbox])

        const { popperRef, popperStyle, placement, refs } = usePopper({
            mounted: shouldDisplayListbox,
            middleware: disableListboxFlip ? [] : undefined
        })

        return (
            <ComponentContainer className={className} style={style}>
                <StyledLabel
                    disabled={disabled}
                    hideLabel={hideLabel}
                    className={dangerouslySetClassNames?.labelClassNames}
                    {...getLabelProps({ htmlFor: autoId })}
                >
                    {label}
                </StyledLabel>
                <div className={dangerouslySetClassNames?.textInputAndListboxContainer}>
                    <TextInputBase
                        id={autoId}
                        disabled={disabled}
                        textInputBorderProps={getComboboxProps({
                            ref: refs.reference as MutableRefObject<HTMLDivElement>
                        })}
                        onBlur={event => {
                            const valueMissing = required && selectedItems.length === 0
                            event.target.setCustomValidity(
                                valueMissing ? "Please fill in this field." : ""
                            )
                        }}
                        onFocus={() => {
                            if (!isOpen && openOnFocus) {
                                openMenu()
                                setAllowBackSpacePill(true)
                            }
                        }}
                        textInputProps={getInputProps({
                            id: autoId,
                            ...getDropdownProps({
                                preventKeyAction: shouldDisplayListbox && !allowBackspacePill,
                            })
                        })}
                        squaredCornersPosition={
                            shouldDisplayListbox && isPlacementTopOrBottom(placement)
                                ? placement
                                : "none"
                        }
                        errorMessage={errorMessage}
                        beforeNode={
                            <>
                                {icon && (
                                    <IconWrapper
                                        disabled={disabled}
                                        sizeVariant="large"
                                        aria-hidden="true"
                                        className={dangerouslySetClassNames?.iconWrapper}
                                    >
                                        {icon}
                                    </IconWrapper>
                                )}
                            </>
                        }
                        renderInput={({ input, ref }) => (
                            <ContentWrapper
                                className={dangerouslySetClassNames?.contentWrapper}
                            >
                                {selectedItems.map((selectedItem, index) => (
                                    <Pill
                                        key={`selected-item-${index}`}
                                        onDelete={e => {
                                            e.stopPropagation();
                                            removeSelectedItem(selectedItem)
                                            ref.current && ref.current.focus()
                                        }}
                                        {...getSelectedItemProps({
                                            selectedItem,
                                            index,
                                            onClick: e => e.stopPropagation()
                                        })}
                                        className={dangerouslySetClassNames?.pill}
                                        name={name}
                                        accessibleLabel={mapOptionToAccessibleString(selectedItem)}
                                        dangerouslySetClassNames={dangerouslySetClassNames}
                                        disabled={disabled}
                                    >
                                        {mapOptionToString(selectedItem)}
                                    </Pill>
                                ))}
                                {input}
                            </ContentWrapper>
                        )}
                        {...remainingProps}
                        dangerouslySetClassNames={{
                            inputBorder: dangerouslySetClassNames?.inputBorder,
                            scrollableArea: dangerouslySetClassNames?.scrollableArea,
                            errorMessage: dangerouslySetClassNames?.errorMessage,
                            helpMessage: dangerouslySetClassNames?.helpMessage,
                            input: dangerouslySetClassNames?.input,
                            clearButton: dangerouslySetClassNames?.clearButton
                        }}
                        ref={ref}
                        onClear={() => {
                            setInputValue("")
                            onInputChange("")
                            reset()
                        }}
                        showClearButton={
                            showClearButton &&
                            (internalInputValue !== "" || selectedItems.length !== 0)
                        }
                    />
                    {shouldDisplayListbox ? (
                        <ListboxWrapper
                            ref={popperRef}
                            popperStyle={popperStyle}
                            className={dangerouslySetClassNames?.listboxWrapper}
                        >
                            <Listbox
                                visible={true}
                                sizeVariant="large"
                                listboxMaxHeight={listboxMaxHeight}
                                visibleOptionsToDisplay={visibleOptionsToDisplay}
                                isErrored={!!errorMessage}
                                placement={placement}
                                {...getMenuProps(
                                    {
                                        ref: listboxRef
                                    },
                                    { suppressRefError: true }
                                )}
                                className={dangerouslySetClassNames?.listbox}
                            >
                                {mapOptionsToListboxNode({
                                    options: filteredOptions,
                                    inputValue: internalInputValue,
                                    mapOptionToString,
                                    mapOptionToListNode,
                                    getItemProps,
                                    highlightedIndex,
                                    mapOptionToAccessibleString,
                                    dangerouslySetClassNames,
                                    noResultsMessage,
                                    noResultState: getNoResultsState(noResults, userNoResults)
                                })}
                            </Listbox>
                        </ListboxWrapper>
                    ) : (
                        <ListboxWrapper
                            popperStyle={{}}
                            className={dangerouslySetClassNames?.listboxWrapper}
                            {...getMenuProps()}
                        >
                        </ListboxWrapper>
                    )}
                </div>
            </ComponentContainer>
        )

    }
)

MultiAutocomplete.displayName = "MultiAutocomplete"

export default MultiAutocomplete;
