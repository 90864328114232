import React, { FC, useEffect } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import defaults from './defaults';
import CssReset from './cssReset';
import {
    NittyProps,
    NittyDefaults,
    PaletteDefaults,
    PaletteTokenObject,
    NittyTheme,
    ThemeCssTokens,
} from './types';
import { mapObject } from './utils';
import { paletteHelper } from '../../utils';

const makeCssVariables = (tokens: ThemeCssTokens, prefix: string) => {
    const tokenKeys = Object.keys(tokens) as Array<keyof ThemeCssTokens>;
    return tokenKeys.map((key) => `--nitty-${prefix}${key}: ${tokens[key]};`);
};

const makePalette = (
    palette: PaletteDefaults,
    mode: keyof PaletteTokenObject,
) => {
    const paletteKeys = Object.keys(palette) as (keyof PaletteDefaults)[];
    return paletteKeys
        .map((key) => `--nitty-${key}: ${palette[key][mode]};`)
        .join('');
};

type NittyStyleProps = {
    cssVariableBorderRadius: string[];
    cssVariableShadows: string[];
    cssVariableSpacing: string[];
    darkPalette: string;
    lightPalette: string;
    darkModeAuto: boolean;
};

const renderDarkModeAutoCSS = (lightPalette: string, darkPalette: string) =>
    `:root {
        ${lightPalette}
    }
    @media (prefers-color-scheme: dark) {
        :root {
            ${darkPalette}
        }
    }`;

const renderDarkModeBooleanCSS = (lightPalette: string, darkPalette: string) =>
    `:root, :root[data-mode='light'] {
        ${lightPalette}
    }
    :root[data-mode='dark'] {
        ${darkPalette}
    }`;

const NittySytles = createGlobalStyle<NittyStyleProps>`
    body {
        line-height: 1.5;
        color: ${paletteHelper('textPrimary')};
        --webkit-font-smoothing: antialiased;
        --moz-osx-font-smothing: grayscale;
    }
    *, *:before, *:after {
        box-sizing: border-box;
    }
    :root {
        ${({ cssVariableBorderRadius }) => cssVariableBorderRadius}
        ${({ cssVariableShadows }) => cssVariableShadows}
        ${({ cssVariableSpacing }) => cssVariableSpacing}
    }
    ${({ darkModeAuto, darkPalette, lightPalette }) =>
        darkModeAuto
            ? renderDarkModeAutoCSS(lightPalette, darkPalette)
            : renderDarkModeBooleanCSS(lightPalette, darkPalette)}

    .Nitty__Icon--md {
        width: 1rem;
        height: 1rem;
    }

    .Nitty__Icon--lg {
        width: 1.5rem;
        height: 1.5rem;
    }
`;

const defaultProps = {
    defaultsOverride: (defaults: NittyDefaults) => defaults,
    darkMode: false,
};

const Nitty: FC<NittyProps> = (props) => {
    const { children, defaultsOverride, darkMode } = {
        ...defaultProps,
        ...props,
    };

    useEffect(() => {
        if (typeof darkMode === 'boolean') {
            document.documentElement.setAttribute(
                'data-mode',
                darkMode ? 'dark' : 'light',
            );
        }
    }, [darkMode]);

    const theme = defaultsOverride(defaults);
    const lightPalette = makePalette(theme.palette, 'light');
    const darkPalette = makePalette(theme.palette, 'dark');

    const effectivePalette = mapObject<
        keyof PaletteDefaults,
        PaletteTokenObject
    >(theme.palette, (k) => `var(--nitty-${k})`);

    const effectiveTheme: NittyTheme = {
        ...theme,
        palette: effectivePalette,
    };

    const cssVariableBorderRadius = makeCssVariables(
        theme.border.radius,
        'radius-',
    );

    const cssVariableShadows = makeCssVariables({ shadow: theme.shadow }, '');
    const cssVariableSpacing = makeCssVariables(theme.spacing, 'spacing-');

    return (
        <>
            <ThemeProvider theme={effectiveTheme}>
                <CssReset />
                <NittySytles
                    darkModeAuto={darkMode === 'auto'}
                    lightPalette={lightPalette}
                    darkPalette={darkPalette}
                    cssVariableBorderRadius={cssVariableBorderRadius}
                    cssVariableShadows={cssVariableShadows}
                    cssVariableSpacing={cssVariableSpacing}
                />
                {children}
            </ThemeProvider>
        </>
    );
};

export default Nitty;
