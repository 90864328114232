import React, { ReactNode } from "react";
import { paletteHelper, spacingHelper } from "../../utils";
import forwardRef from "../../private/forwardRef";
import Card from "../card";
import { CrossLg } from '../icons';
import styled from "styled-components";
import Button from "../button";
import { useInnerHeight } from "../../private/useInnerHeight";
import { DialogProps, DialogHeaderAlignment } from "./dialog.types";

const DialogBox = styled(Card)`
    background-color: ${paletteHelper("backgroundPrimaryHigh")};
    max-width: ${({ maxWidth }) => maxWidth};
    margin: ${spacingHelper("medium")};
    max-height: ${({ $maxHeight }) => `calc(${$maxHeight} - 2rem)`};
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: auto;

    ${({ autoFullScreen, $maxHeight, fullScreenMediaQuery }) =>
        autoFullScreen &&
        `@media screen and ${fullScreenMediaQuery} {
            max-width: unset;
            max-height: ${$maxHeight};
            width: 100vw;
            height: 100vh;
            margin: 0;
            border-radius: 0;
        }`}
`

type DialogHeaderProps = {
    alignment: DialogHeaderAlignment
}

const DialogHeader = styled.div<DialogHeaderProps>`
    min-height: 3rem;
    flex-shrink: 0;
    padding: ${({ alignment }) =>
        alignment === "left"
            ? spacingHelper("medium threeExtraLarge medium medium")
            : spacingHelper("medium threeExtraLarge")};
    text-align: ${({ alignment }) => (alignment === "left" ? "left" : "center")};
    ${({ children, theme }) =>
        children && `border-bottom: 1px: solid ${theme.palette.borderSecondary};`}
`

const DialogFooter = styled.div`
    padding: ${spacingHelper("medium")};
    border-top: ${paletteHelper("1px solid borderSecondary")};
`

const CloseButton = styled(Button).attrs({
    variant: "naked",
    hideLabel: true,
    sizeVariant: "medium",
    compact: true,
    icon: <CrossLg />
})`
    position: absolute;
    right: ${spacingHelper("extraSmall")};
    top: ${spacingHelper("extraSmall")};
`;

type DialogBodyProps = {
    header: ReactNode
    footer: ReactNode
    noMargins: boolean
}

const DialogBody = styled.div<DialogBodyProps>`
    flex-grow: 1;
    ${({ header, noMargins }) =>
        !header && `margin-top: ${noMargins ? "0" : "3.5rem"};`}
    overflow: auto;
`;

const defaultProps = {
    maxWidth: "25rem",
    autoFullScreen: false,
    fullScreenMediaQuery: "(max-width: 31.25rem)",
    headerAlignment: "left" as DialogHeaderAlignment,
    noMargins: false
};

const Dialog = forwardRef<DialogProps, "div">((props, externalRef) => {
    const {
        children,
        onClose,
        dangerouslySetClassNames,
        autoFullScreen,
        fullScreenMediaQuery,
        headerAlignment,
        header,
        footer,
        maxWidth,
        noMargins,
        ...remainingProps
    } = { ...defaultProps, ...props }

    const innerHeight = useInnerHeight();

    return (
        <DialogBox
            autoFullScreen={autoFullScreen}
            fullScreenMediaQuery={fullScreenMediaQuery}
            maxWidth={maxWidth}
            $maxHeight={innerHeight}
            ref={externalRef}
            {...remainingProps}
        >
            {header && (
                <DialogHeader
                    alignment={headerAlignment}
                    className={dangerouslySetClassNames?.header}
                >
                    {header}
                </DialogHeader>
            )}
            <DialogBody
                className={dangerouslySetClassNames?.body}
                header={!!header}
                footer={!!footer}
                noMargins={noMargins}
                data-modal-scroll-enable
            >
                {children}
            </DialogBody>
            {footer && (
                <DialogFooter
                    className={dangerouslySetClassNames?.footer}
                >
                    {footer}
                </DialogFooter>
            )}
            {onClose && (
                <CloseButton
                    className={dangerouslySetClassNames?.closeButton}
                    onClick={onClose}
                >
                    Close dialog
                </CloseButton>
            )}
        </DialogBox>
    )
});

Dialog.displayName = "Dialog";

export default Dialog;
