import React from "react";
import forwardRef from "../../../private/forwardRef";
import { IconProps } from '../icon.types';
import { nanoid } from "nanoid/non-secure"

const GpsMd = forwardRef<IconProps, "svg">(({ className, ...props}, ref) => {
    const titleId = props?.id ? `${props.id}-title` : nanoid(5);
    return (
        <svg
            className={["Nitty__Icon--md", className].filter(Boolean).join(" ")}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            aria-labelledby={props?.title ? titleId : undefined}
            {...props}
        >
            {props?.title && <title id={titleId}>{props.title}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="currentColor"
                d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4zm8.94 3A8.994 8.994 0 0 0 13 3.06V2c0-.55-.45-1-1-1s-1 .45-1 1v1.06A8.994 8.994 0 0 0 3.06 11H2c-.55 0-1 .45-1 1s.45 1 1 1h1.06A8.994 8.994 0 0 0 11 20.94V22c0 .55.45 1 1 1s1-.45 1-1v-1.06A8.994 8.994 0 0 0 20.94 13H22c.55 0 1-.45 1-1s-.45-1-1-1h-1.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7s7 3.13 7 7s-3.13 7-7 7z"
            />
        </svg>
    )
})

GpsMd.displayName = "GpsMd"

export default GpsMd;
