import React from "react"
import forwardRef from "../../private/forwardRef"
import { TextProps } from "./text.types"
import Text from "."

const H1 = forwardRef<TextProps, "h1">((props, ref) => (
    <Text as="h1" ref={ref} {...props} />
))

const H2 = forwardRef<TextProps, "h2">((props, ref) => (
    <Text as="h2" ref={ref} {...props} />
))

const H3 = forwardRef<TextProps, "h3">((props, ref) => (
    <Text as="h3" ref={ref} {...props} />
))

const H4 = forwardRef<TextProps, "h4">((props, ref) => (
    <Text as="h4" ref={ref} {...props} />
))

const H5 = forwardRef<TextProps, "h5">((props, ref) => (
    <Text as="h5" ref={ref} {...props} />
))

const H6 = forwardRef<TextProps, "h6">((props, ref) => (
    <Text as="h6" ref={ref} {...props} />
))

const P = forwardRef<TextProps, "p">((props, ref) => (
    <Text as="p" ref={ref} {...props} />
))

const Small = forwardRef<TextProps, "small">((props, ref) => (
    <Text as="small" ref={ref} {...props} />
))

const FigCaption = forwardRef<TextProps, "figcaption">((props, ref) => (
    <Text as="figcaption" ref={ref} {...props} />
))

const Strong = forwardRef<TextProps, "strong">((props, ref) => (
    <Text as="strong" ref={ref} {...props} />
))

export { H1, H2, H3, H4, H5, H6, P, Small, FigCaption, Strong }